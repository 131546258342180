<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">消费记录</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
        <span class="back">返回</span>
      </div>
    </div>
    <!--       消费记录 -->
    <van-list
      v-if="consumeList && consumeList.length > 0"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      style="padding-top: 3rem;"
    >
      <div class="acc">
        <div class="item-margin">
          <ul
            v-if="consumeList && consumeList.length > 0"
            id="box"
            v-infinite-scroll="load"
            infinite-scroll-disabled="disabled"
            class="infinite-list"
            style="overflow:auto;"
          >
            <li v-for="(item, index) in consumeList" id="box-item" :key="index" class="consume">
              <div class="order-title">
                <div class="order-num">{{ item.consumeTime || item.endTime }}</div>
              </div>
              <div class="order-content">
                <div class="order-line">
                  <div class="order-text">订单号</div>
                  <div class="order-text">{{ item.id ? item.id : '更正' }}</div>
                </div>
                <div class="order-line two">
                  <div class="order-text">消费金额</div>
                  <div
                    v-if="item.consumeMoney>=0"
                    class="order-text money"
                  >-{{ getMoney(item.consumeMoney/100) }}</div>
                  <div
                    v-if="item.consumeMoney<0"
                    class="order-text money"
                  >+{{ getMoney(item.consumeMoney/100).split('-')[1] }}</div>
                  <!-- <div class="order-text money">{{ item.consumeMoney>=0 ? -(getMoney(item.consumeMoney/100)) : +(getMoney(item.consumeMoney/100).split('-')[1]) }}</div> -->
                </div>
                <div class="three">
                  <div class="order-text">扣费钱包明细</div>
                  <div style="display:flex;flex-direction: column;font-size: 12px;">
                    <!-- <div class="order-text money">-{{ getMoney(item.consumeMoney/100) }}</div> -->
                    <div
                      style="display:flex;flex-direction: row;justify-content: space-between;margin-top: 5px;"
                    >
                      <div class="order-text">优惠钱包</div>
                      <div
                        v-if="item.use_coupon && item.use_coupon>=0"
                        class="order-text money"
                      >-{{ getMoney(item.use_coupon/100) }}</div>
                      <div
                        v-else-if="item.use_coupon && item.use_coupon<0"
                        class="order-text money"
                      >+{{ getMoney(item.use_coupon/100).split('-')[1] }}</div>
                      <div v-else class="order-text money">-0.00</div>
                      <!-- <div class="order-text money">-￥20.00</div> -->
                    </div>
                    <div
                      style="display:flex;flex-direction: row;justify-content: space-between;margin-top: 5px;"
                    >
                      <div class="order-text">现金钱包</div>
                      <div
                        v-if="item.use_balance && item.use_balance>=0"
                        class="order-text money"
                      >-{{ getMoney(item.use_balance/100) }}</div>
                      <div
                        v-else-if="item.use_balance && item.use_balance<0"
                        class="order-text money"
                      >+{{ getMoney(item.use_balance/100).split('-')[1] }}</div>
                      <div v-else class="order-text money">-0.00</div>
                      <!-- <div class="order-text money">-￥80.00</div> -->
                    </div>
                    <div
                      style="display:flex;flex-direction: row;justify-content: space-between;margin-top: 5px;"
                    >
                      <div class="order-text">补助钱包</div>
                      <div
                        v-if="item.use_allowance && item.use_allowance>=0"
                        class="order-text money"
                      >-{{ getMoney(item.use_allowance/100) }}</div>
                      <div
                        v-else-if="item.use_allowance && item.use_allowance<0"
                        class="order-text money"
                      >+{{ getMoney(item.use_allowance/100).split('-')[1] }}</div>
                      <div v-else class="order-text money">-0.00</div>
                      <!-- <div class="order-text money">-￥0.00</div> -->
                    </div>
                    <div
                      style="display:flex;flex-direction: row;justify-content: space-between;margin-top: 5px;"
                    >
                      <div class="order-text">图片</div>
                      <div class="order-text money" @click="previewImageFn(item.photo)">
                        <img
                          style="width:1.5rem; height:1.5rem"
                          :src="item.photo?`data:image/png;base64,${item.photo}` : none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <!-- <van-overlay :show="consumeList.length === 0" class="init">
            <div>
              <van-loading color="#1989fa" />
            </div>
          </van-overlay>-->
        </div>

        <!-- <div class="item-margin">
        <ul
          id="box"
          v-infinite-scroll="load"
          infinite-scroll-disabled="disabled"
          class="infinite-list"
          style="overflow:auto;"
        >
          <li id="box-item" class="consume">
            <div class="three">
              <div class="order-text">扣费钱包明细</div>
              <div style="display:flex;flex-direction: column;font-size: 12px;">
                <div style="display:flex;flex-direction: row;justify-content: space-between;margin-top: 5px;">
                  <div class="order-text">优惠钱包</div>
                  <div class="order-text money">-￥20.00</div>
                </div>
                <div style="display:flex;flex-direction: row;justify-content: space-between;margin-top: 5px;">
                  <div class="order-text">现金钱包</div>
                  <div class="order-text money">-￥80.00</div>
                </div>
                <div style="display:flex;flex-direction: row;justify-content: space-between;margin-top: 5px;">
                  <div class="order-text">补助钱包</div>
                  <div class="order-text money">-￥0.00</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        </div>-->
      </div>
    </van-list>
    <van-empty v-if="consumeList.length === 0" description="暂无数据" style="padding-top: 40%" />
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { getDetail } from "@/api/api";
// import moment from 'moment'
import { timeToDate, getMoney } from "../../utils/util";
import none from "@/assets/images/photo_none.png";
import { ImagePreview } from "vant";

export default {
  name: "Detail",
  data() {
    return {
      none,
      cancel,
      consumeList: [],
      finished: false,
      loading: false,
      pagination: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.detailOrder();
  },
  methods: {
    timeToDate,
    getMoney,
    toHome() {
      this.$router.push({ name: "Home" });
    },
    load() {
      this.consumeList.push(this.consumeList);
    },
    async detailOrder() {
      const id = this.$store.getters.userId;
      const res = await getDetail({ id, ...this.pagination });
      const { code, data } = res;
      if (code === 0) {
        data.records.map((item) => {
          this.consumeList.push(item);
        });
        this.pagination = {
          current: data.current,
          size: data.size,
          total: data.total,
        };
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    previewImageFn(data) {
      ImagePreview([data ? `data:image/png;base64,${data}` : none]);
    },
    onLoad() {
      const {
        pagination: { current, size, total },
        detailOrder,
      } = this;
      if (current * size >= total) {
        this.finished = true;
      } else {
        this.pagination = { current: current + 1, size, total };
        detailOrder();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }
    & > .nav_left_btn:active {
      opacity: 0.5;
    }
    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }

  & > .acc {
    padding-top: 3rem;
  }
  .item-margin {
    overflow: scroll;

    .consume {
      background-color: #fff;
      padding: 0.5rem 1.5rem 0.5rem;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      margin-top: 0.3rem;

      & > .order-title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;

        & > .order-num {
          color: #888888;
          font-size: 1rem;
          padding: 0.5rem 0;
        }
      }

      & > .order-content {
        padding: 0.5rem 0;
        //border-bottom: 1px solid #e5e5e5;

        & > .order-line {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > .order-text {
            font-size: 1rem;
          }
          & > .order-text.money {
            color: #66a965;
            font-size: 1rem;
          }
        }

        & > .order-line.two {
          margin-top: 0.4rem;
        }
      }

      & > .order-up {
        display: flex;
        justify-content: center;
        padding: 0.1rem 0;
        transform: rotate(180deg);
        transition: height 2s;
      }

      .to_stretch {
        height: 10rem;
      }
    }
  }
}
::v-deep .init.van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 4000;
}
</style>
